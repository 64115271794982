import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileSuccess } from "../reducers/authReducer";
import { getmemeberInfo } from "../reducers/configReduser";
import {store} from '../store';
const CallbackPage =(props)=> {
    const { auth: { deviceToken } } = store.getState();
    useEffect(()=>{
        props.getmemeberInfoa()
        setTimeout(()=>{
            handleRedirect()
        },2000)
       
    },[deviceToken])
   const handleRedirect = (user) => {
       props?.history?.push("/dashboard")
        localStorage.removeItem("__url");
    }

        return (<>
            <div className="loader">Loading .....</div>
            </>);
 
}
const mapStateToProps = ({ oidc }) => {
    return { oidc }
}
const mapDispatchToProps = dispatch => {
    return { updateProfile: (info) => { dispatch(profileSuccess(info)) },getmemeberInfoa: (useremail) => {
        dispatch(getmemeberInfo(useremail));
      }, }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallbackPage));