import GridList from "../grid.component";
import { connect } from "react-redux";
import React from "react";
import { onExcellExport } from '../grid.component/subscribir';
import { Tooltip } from 'antd'

const Commitions = ({userConfig}) =>{
    const columns = [
		{
			field: "date",
			title: "Date",
			filter: true,
			isShowTime: true,
			filterType: "date",
			locked: true,
			width: 400,
			
		},
		{ field: "name", title: "Business Name/Personal Name", filter: true,width:380 },
		{ field: "value", title: "Bonus Earned", filter: true, dataType: "number", filterType: "numeric",width:390 },
		
	]
    const gridRef = React.createRef();
    return(<>
	<div className="main-container">
        <div className="box basic-info text-white mt-36">
		<div class="d-flex align-center justify-content mb-16">
			<h1 class="basicinfo mb-0"><span class="ant-typography basicinfo">Bonuses</span></h1>
			<Tooltip placement="top" title={"Export Excel"}>
            <span className={`icon c-pointer xl mr-0 excelexport` }onClick={() => {onExcellExport()}}>
			</span>
         </Tooltip>

		
		</div>
					<GridList
						showActionBar={true}
						ref={gridRef}
						url={process.env.REACT_APP_GRID_API_END_POINT + `Partner/GetBonusesK/Customer`}
						columns={columns}
						excelFileName = {'Bonuses'}
					/>
				</div>
				</div>
        </>
    )
}
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(Commitions);