import { create } from "apisauce";
import { store } from '../store';
import CryptoJS from 'crypto-js'
const apiClient = create({
    baseURL: process.env.REACT_APP_CARDS_API_END_POINT
});
const apiMain = create({
    baseURL: process.env.REACT_APP_API_END_POINT
});
const apigrid = create({
    baseURL: process.env.REACT_APP_GRID_API_END_POINT
});
const _encrypt = (msg, key) => {

    msg = typeof (msg) == 'object' ? JSON.stringify(msg) : msg;
    var salt = CryptoJS.lib.WordArray.random(128 / 8);

    key = CryptoJS.PBKDF2(key, salt, {
        keySize: 256 / 32,
        iterations: 10
    });

    var iv = CryptoJS.lib.WordArray.random(128 / 8);

    var encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC

    });
    return ((salt.toString()) + (iv.toString()) + (encrypted.toString()));
}
apiClient.axiosInstance.interceptors.request.use((config) => {
    const { auth: { deviceToken,profile }} = store.getState()
    config.headers.Authorization = `Bearer ${deviceToken}`
    if (profile?.id) config.headers.AuthInformation = profile?.id ? _encrypt(`{CustomerId:"${profile?.id}"}`, profile.sk) : ''
    return config;
})
apiMain.axiosInstance.interceptors.request.use((config) => {
    const { auth: { deviceToken,profile }} = store.getState()
    config.headers.Authorization = `Bearer ${deviceToken}`
    if (profile?.id) config.headers.AuthInformation = profile?.id ? _encrypt(`{CustomerId:"${profile?.id}"}`, profile.sk) : ''
    return config;
})
apigrid.axiosInstance.interceptors.request.use((config) => {
    const { auth: {  deviceToken,profile } } = store.getState()
    config.headers.Authorization = `Bearer ${deviceToken}`
    if (profile?.id) config.headers.AuthInformation = profile?.id ? _encrypt(`{CustomerId:"${profile?.id}"}`, profile.sk) : ''
    return config;
})
export { apiClient, apiMain, apigrid }