import { Component } from 'react'
import { Route,Switch} from 'react-router-dom';
import Home from '../components/home';
import CallbackPage from '../auth/callback.component';
import OnBoarding from './onboard.component';
import Dashboard from '../components/dashboard.component';
import Commitions from '../components/commitions.component';
import Refer from '../components/refer.component';
import Referals from '../components/referals.component';
class RouteConfig extends Component {

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/home" component={Home} />
                    <Route path="/callback" component={CallbackPage} />
                    <Route path="/onboading" component={OnBoarding} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/referrals" component={Referals} />
                    <Route path="/refer" component={Refer} />
                    <Route path="/bonuses" component={Commitions} />
                </Switch>
            </>
        )
    }
}

export default RouteConfig;