import React, { Component } from 'react';
import { List,Typography, Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import {getpartnerDashboardCryptos} from '../../api/apiCalls'
import { connect } from 'react-redux';

class YourPortfolio extends Component {
    state = {
        loading: true,
        wallets: [],
        portfolioData: [], buyDrawer: false
    }
    componentDidMount() {
      this.fetchWallets();
    }
    fetchWallets= async()=> {
      const fiatRes = await getpartnerDashboardCryptos("Partner");
      if (fiatRes.ok) {
        this.setState({ ...this.state, wallets: fiatRes.data,loading:false });
      }
    }
 
    render() {
        const { Title, Text } = Typography;
        const {wallets,loading} = this.state;
        return (
          <div className="portfolio-list">
           
            <div style={{ display: "flex",alignItems:"baseline"}}>
            <Title className="fs-20 text-white mb-0 fw-600 px-8">Crypto Balances</Title>
            </div>
            <List
              className="mobile-list"
              itemLayout="horizontal"
              dataSource={wallets}
              loading={loading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'No data Avilable'}
                  />
                )
              }}
              renderItem={(item) => (
                <List.Item
                  className=""
                  >
                  <List.Item.Meta
                    avatar={
                      <span
                        className={`coin  ${item.coin}`}
                      />
                    }
                    title={
                      <div className="mr-16">
                        <div className="d-flex justify-content"style={{ display: "flex", alignItems: "center" }}>
                          <div>
                          <Text className="fs-18 fw-400 text-upper text-white">
                            {item.coin}
                          </Text>
                          </div>
                          <div>
                          <Text className='text-white'>{item.coinBalance}</Text>
                        </div>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        );
    }
}
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(withRouter(YourPortfolio));
