import React, { Component } from 'react';
import success from '../../assets/images/success.png';
import { Typography, Space } from 'antd';
import {setStepcode,setTransforObj} from '../../reducers/transferReducer'
import { connect } from 'react-redux';

class TransforSuccessMsg extends Component {
    
    render() {
        const { Title, Paragraph, Text } = Typography;
        return (
            <>
                <div className="success-pop text-center pt-24">
                    <img src={success} className="confirm-icon" alt={"success"} />
                    <Title className="text-white-30 fs-36 fw-200 mb-4">Thank You</Title>
                    <Paragraph className="fs-14 text-white-30 fw-200">
                        <Text className="fs-14 text-white-30 fw-200">Your transaction was success,</Text> 
                        {this.props.transforObj?.transferAmount} {this.props.transforObj?.walletCode} 
                        <Text className="fs-14 text-white-30 fw-200">has been added into your wallet.</Text>
                        </Paragraph>
                    <Space direction="vertical" size="large">
                        <Text className="f-16 text-white-30 mt-16 text-underline" onClick={() => {this.props.changeStep("tranforcoin");this.props.clearData(null)}}></Text>
                    </Space>
                </div>
            </>
        );
    }
}
const connectStateToProps = ({ userConfig, TransforStore }) => {
    return { customer: userConfig.userProfileInfo,transforObj:TransforStore.transforObj }
}
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setStepcode(stepcode))
        },
        clearData: (stepcode) => {
            dispatch(setTransforObj(stepcode))
        }
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(TransforSuccessMsg);
