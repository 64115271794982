import React, { Component } from 'react';
import { Typography, List,Image } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {getpartnerDashboardFiats} from '../../api/apiCalls'


class Wallets extends Component {
  state = {
    wallets: [],
    loading: true,
  };
  componentDidMount() {
    this.fetchWallets();
  }
  fetchWallets= async()=> {
    const fiatRes = await getpartnerDashboardFiats("Partner");
    if (fiatRes.ok) {
      this.setState({ ...this.state, wallets: fiatRes.data,loading:false });
    }
  }

  render() {
    const { Title, Text } = Typography;
      const {wallets} = this.state;
    return (
      <>
        <Title
          className="fs-24 fw-600 mb-12 text-white px-4"
        >Partner Balance</Title>
        <Title
          className="fs-20 fw-600 mb-12 text-white px-4"
        >Fiat Balances</Title>
        <List
          itemLayout="horizontal"
          dataSource={wallets}
          bordered={false}
          className="mobile-list"
          loading={this.state.loading}
          renderItem={(item) => (
            <List.Item className="py-10 px-0">
              <List.Item.Meta
                avatar={<Image preview={false} src={item.imagePath} />}
                title={
                 <div className='d-flex justify-content'>
                  <div className="fs-16 fw-600 text-upper text-white-30 l-height-normal">
                    {item.walletCode}
              
                  </div>
                  <div><Text className='text-white'>{item.amount ||0}</Text></div>
                  </div>
                }
              />
               </List.Item>
          )}
        />
      </>
    );
  }
}
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo };
};

export default connect(connectStateToProps)(withRouter(Wallets));