export function getConfig() {
    return {

      domain: process.env.REACT_APP_AUTHORITY,

      clientId: process.env.REACT_APP_CLIENT_ID,

      audience:  process.env.REACT_APP_AUDIENCE,

    };

  }