import { Component } from "react";
import {  } from '../api/apiCalls';
    class Home extends Component{

    render(){
        return (
            <>home
            </>
        )
    }
}

export default Home;