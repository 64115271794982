

const Refer = () =>{


    return(<>
        <h1 style={{color:'white'}}>Refer screen</h1>
        </>
    )
}

export default Refer;