import { apiMain} from "../../api/clients";
import { ApiControllers } from "../../api/config"


const gettransforLu = () => {
	return apiMain.get(ApiControllers.common + `PartnerWalletTypesLU`);
};
const getCurrencyLu = () => {
	return apiMain.get(ApiControllers.earn + `ListofCurrencies` );
};
const getcurrencyBalance = (fromwallet,currency) => {
	return apiMain.get(ApiControllers.earn + `AvailableBalance/${fromwallet}/${currency}` );
};
const saveTransfor = (obj) => {
	return apiMain.post(ApiControllers.earn + `SaveTransfer`,obj );
};

export {gettransforLu, getCurrencyLu, getcurrencyBalance, saveTransfor}