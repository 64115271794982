import { Row, Col, Spin, Tabs, Table } from "antd";
import { useEffect, useState } from "react";
import { getpartnerDashboardErnings, getpartnerTranDashboard, convertUTCToLocalTime } from "../../api/apiCalls";
import { connect } from "react-redux";
import Wallets from "./wallets.component";
import YourPortfolio from "./yourportfolio.component";
import Moment from 'react-moment'
import NumberFormat from 'react-number-format';

const { TabPane } = Tabs;

const Dashboard = ({ userConfig }) => {
  useEffect(() => {
    loadPartnerErnings(0);
    loadPartnertrans();
  }, []);
  const [eranBal, setEarnBal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [kpisLoader, setKpisLoader] = useState(false);
  const onChange = (key) => {
    loadPartnerErnings(key);
  };
  const loadPartnerErnings = async (Days) => {
    let todate = Days==0?null:getdate(0);
    let fromdate = Days==0?null:getdate(Days);
    setKpisLoader(true)
    const eranRes = await getpartnerDashboardErnings(fromdate, todate);
    if (eranRes.ok) {
      setEarnBal(eranRes.data);
      setLoading(false);
      setKpisLoader(false)
    } else {
      setLoading(false);
      setKpisLoader(false)
    }
    
  };
  const loadPartnertrans = async () => {
    setLoading(true);
    const transRes = await getpartnerTranDashboard();
    if (transRes.ok) {
      setDataSource(transRes.data.data);
      setLoading(false);
    }else{
      setLoading(false);
    }
    
  };
  const getdate = (days) => {
    var date = new Date();
    var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    var day = last.getDate();
    var month = last.getMonth() + 1;
    month=month>9?month:"0"+month
    var year = last.getFullYear();
    return year+"-"+month+"-"+day;
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render:text => <Moment format="DD/MM/YYYY">{convertUTCToLocalTime(text)}</Moment>
    },
    {
      title: 'Business Name / Personal Name',
      dataIndex: 'name',
      key: 'Name',
      width:'240px'
    },
    {
      title: 'Bonus Earned',
      dataIndex: 'value',
      key: 'BonusEarned',
      render:text => <NumberFormat value={text} decimalSeparator="." displayType={'text'} thousandSeparator={true} />
    },
   


  ];

  const renderTabs = () => {
    return (
      <div className="partner-bg">
        <Spin spinning={kpisLoader}>
        <Row gutter={8} className="kpi-List">
          <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
            <div className="db-kpi">
              <label className="kpi-label">
                Your Earnings 
              </label>
              <div className=" kpi-val fs-20">
                {eranBal?.yourEarnings || 0} {userConfig?.lCurrency}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
            <div className="db-kpi">
              <label className="kpi-label">
                Members Who Started Trading{" "}
              </label>
              <div className=" kpi-val fs-20">{eranBal?.tradingMembers ||0}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
            <div className="db-kpi">
              <label className="kpi-label">
                Members 
              </label>
              <div className=" kpi-val fs-20">{eranBal?.totalMembers||0}</div>
            </div>
          </Col>
        </Row>
        </Spin>
      </div>
    );
  };
  return (
    <>
      <div className="main-container text-white mt-24">
        <Row justify="mt-36">
          <Col xs={24} md={11} xl={9}>
            <div className="markets-panel mb-36">
              <Wallets />
            </div>
            <div className="markets-panel">
              <YourPortfolio />
            </div>
          </Col>
          <Col xs={24} md={13} xl={15}>
            
            <div className="card-container crypto-list-tabs">
              <Tabs defaultActiveKey="0" onChange={onChange} className="mb-24 fs-14 kpi-tabs">
                <TabPane tab={<span>
                        <span  />
                       All</span>} key="0" className="px-24">
                {renderTabs()}
                </TabPane>
                <TabPane className="fs-14  px-24" tab={<span>
                        <span  />Yesterday</span>} key="1">
                  {renderTabs()}
                </TabPane>
                <TabPane className="fs-14 px-24" tab={<span>
                        <span  />This week</span>} key="6">
                  {renderTabs()}
                </TabPane>
                <TabPane className="fs-14  px-24" tab={<span>
                        <span  />This Month</span>} key="30">
                  {renderTabs()}
                </TabPane>
              </Tabs>
            </div>
            <h1 class="basicinfo mb-0 bonous-title"><span class="ant-typography basicinfo">Bonuses</span></h1>
            <div className="box basic-info dashbord-bonus text-white bg-none responsive_table mt-16">
            <Spin spinning={loading}>
               <div className="pay-grid view bonus-tbl mb-view">
                <Table  dataSource={dataSource} columns={columns} pagination={false}  />
              </div>
              </Spin>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps)(Dashboard);
